export default function ImgProxy(src: string, options: string) {
  if (!src) {
    return undefined;
  }

  const baseUrl = src.startsWith("/")
    ? import.meta.env.VITE_COG_FRONT_BASE_URL
    : "";

  let img_base_url = baseUrl + src;

  if (import.meta.env.VITE_COG_BACKEND_AUTH_LOGIN.length > 0) {
    img_base_url = img_base_url.replace(
      "https://",
      `https://${import.meta.env.VITE_COG_BACKEND_AUTH_LOGIN}:${
        import.meta.env.VITE_COG_BACKEND_AUTH_PASSWORD
      }@`,
    );
  }

  const originalUrlEncoded = btoa(img_base_url);

  if (import.meta.env.VITE_COG_IMGPROXY_DEBUG == 1) {
    console.groupCollapsed("Image call");
    console.log("From: ", src);
    console.log("To: ", img_base_url);
    console.groupEnd();
  }

  return `${
    import.meta.env.VITE_COG_IMGPROXY_BASE_URL
  }/${options}/${originalUrlEncoded}`;
}
